import { UseEventSourceOptions, useEventSource } from "@vueuse/core";

export const useChatAi = (url: string, params?: Object, autoFetch?: boolean, events?: string[], options?: UseEventSourceOptions) => {
  const { backendUrl } = useRuntimeConfig().public;

  const pending = ref<boolean>(false);
  const status = ref<"OPEN" | "CONNECTING" | "CLOSED">("CLOSED");
  const eventSource = ref<EventSource | null>(null);
  const event = ref<string | null>(null);
  const data = ref<string | null>(null);
  const messages = ref<string>("");
  const error = ref<Event | null>(null);
  let close: () => void = () => {};

  const queryString = params ? "?" + new URLSearchParams(Object.entries(params)).toString() : "";

  function fetch() {
    pending.value = true;

    const {
      status: resStatus,
      eventSource: resEventSource,
      event: resEvent,
      data: resData,
      error: resError,
      close: resClose,
    } = useEventSource(`${backendUrl}/ai/${url}${queryString}`, events ?? ["message", "end"], {
      withCredentials: true,
      ...options,
    });

    watch(resStatus, (value) => (status.value = value));
    watch(resEventSource, (value) => (eventSource.value = value));
    watch(resEvent, (value) => (event.value = value));
    watch(resData, (value) => (data.value = value));
    watch(resError, (value) => (error.value = value));

    watch(resEvent, (event) => {
      if (event === "message" && resData.value) {
        messages.value += resData.value.replace(/%NL%/g, "\n");
      } else if (event === "end") {
        closeConnection();
      }
    });

    close = resClose;
  }

  const refresh = () => {
    if (pending.value) {
      return false;
    }

    status.value = "CLOSED";
    eventSource.value = null;
    event.value = null;
    data.value = null;
    messages.value = "";
    error.value = null;

    fetch();
  };

  const closeConnection = () => {
    close();
    pending.value = false;
  };

  watch(error, () => closeConnection());

  window.addEventListener("beforeunload", () => closeConnection());

  if (autoFetch) {
    fetch();
  } else {
    pending.value = false;
  }

  return {
    pending,
    status,
    eventSource,
    event,
    data,
    messages,
    error,
    refresh,
    close,
  };
};
