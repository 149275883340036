<template>
  <div class="rounded border">
    <p class="whitespace-pre-wrap break-words p-8">
      <span v-if="currentIndex === 0 && initialMessage && partnerName" class="mb-4 text-sm font-semibold">This post was suggested by {{ partnerName }}</span>
      <span v-if="error" class="flex items-center space-x-2.5 text-red-600">
        <Icon icon="times" />
        <span>Oops! Something went wrong!</span>
      </span>
      <span v-else-if="!currentChat.message" class="block">
        <Icon icon="spinner-third" prefix="fad" spin />
      </span>
      <span v-else>{{ currentChat.message }}</span>
    </p>

    <div class="flex divide-x border-t text-sm">
      <div class="flex shrink-0 items-center text-supplement">
        <button @click="previousChat" class="py-4 pl-5 pr-4" :class="currentIndex === 0 ? 'cursor-not-allowed text-gray-300' : `hover:text-paragraph`">
          <Icon icon="chevron-left" />
        </button>
        <span>{{ currentIndex + 1 }} / {{ chats.length }}</span>
        <button @click="nextChat" class="py-4 pl-4 pr-5" :class="currentIndex === lastIndex ? 'cursor-not-allowed text-gray-300' : 'hover:text-paragraph'">
          <Icon icon="chevron-right" />
        </button>
      </div>
      <div class="flex grow items-center justify-end">
        <div v-if="pending" class="px-5 py-4">
          <Icon icon="spinner-third" prefix="fad" spin />
        </div>
        <button v-else @click="refreshChat" class="space-x-3 p-5 py-4">
          <span>Impress me! Show me something different</span>
          <Icon icon="refresh" prefix="far" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface ChatAiProps {
  modelValue: string | null | undefined;
  endpoint: string;
  params?: Object;
  partnerName?: string;
  initialMessage?: string;
  replace?: Record<string, string>;
}

const props = defineProps<ChatAiProps>();
const emit = defineEmits(["update:modelValue", "pending"]);

// Chats
const { pending, messages, error, refresh } = useChatAi(props.endpoint, props.params, props.initialMessage ? false : true);

const chats = ref([{ message: props.initialMessage || "" }]);
const currentIndex = ref(0);

const currentChat = computed(() => {
  let chat = chats.value[currentIndex.value];

  if (props.replace) {
    Object.entries(props.replace).forEach(([key, value]) => {
      chat.message = chat.message.replace(key, value);
    });
  }

  return chat;
});

const lastIndex = computed(() => chats.value.length - 1);

emit("pending", pending.value);

watch(pending, (value) => emit("pending", value));

watch(messages, (value) => {
  if (value) {
    chats.value[lastIndex.value].message = value;

    if (currentIndex.value === lastIndex.value) {
      emit("update:modelValue", currentChat.value.message);
    }
  }
});

// Chat Navigation
const previousChat = () => {
  if (currentIndex.value > 0) {
    --currentIndex.value;
    emit("update:modelValue", currentChat.value.message);
  }
};

const nextChat = () => {
  if (currentIndex.value < lastIndex.value) {
    ++currentIndex.value;
    emit("update:modelValue", currentChat.value.message);
  }
};

// Regenerate chat
const refreshChat = () => {
  if (pending.value) {
    return false;
  }

  chats.value.push({ message: "" });
  currentIndex.value = chats.value.length - 1;
  emit("update:modelValue", currentChat.value.message);

  refresh();
};
</script>
